.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-box {
  background: #adbfc9;
  border-radius: 64px;
  text-align: center;
  padding: 25px;
  margin-top: -60px;
}

.skill h5 {
  font-size: 20px;
  pointer-events: none;
}

@media (max-width: 767px) {
  .skill h5 {
    font-size: 25px;
  }
}

.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  max-width: 100px;
  height: 100px;
  margin: 0 auto 15px auto;
}

.background-image {
  background: rgb(149, 180, 181);
  background: radial-gradient(
    circle,
    rgba(149, 180, 181, 1) 0%,
    rgba(170, 157, 181, 1) 100%
  );

  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -4;
}

.react-multi-carousel-track {
  display: flex;
  align-items: end;
}

.react-multiple-carousel__arrow {
  z-index: 0;
}
