/* Define color variables */
:root {
  --color-white: #ffffff;
  --color-dark-shadow: rgba(0, 0, 0, 0.1);
  --color-light-blue: #95b4b5;
  --color-pink: #e0bdcc;
  --color-background-start: rgb(149, 180, 181);
  --color-background-end: rgb(170, 157, 181);
  --color-hover-text: #009c97;
}

.projects {
  position: relative;
}



.projects h2 {
  font-size: 35px;
  text-align: center;
}
.projects p {
  color: var(--color-white);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

p {
  color: var(--color-white);
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, var(--color-light-blue) -5.91%, var(--color-pink) 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.section#project {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.background-image {
  background: var(--color-background-start);
  background: radial-gradient(
    circle,
    var(--color-background-start) 0%,
    var(--color-background-end) 100%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -4;
}

@media (max-width: 767px) {
  .col {
    background: var(--color-background-start);
  }
  .skill {
    padding: 0;
  }
  h2 {
    padding-top: 20px;
  }
}

.nav-pills {
  display: flex;
  justify-content: space-evenly;
  margin: 0 450px;
}

.nav-pills .nav-link {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--color-dark-shadow);
  color: var(--color-white);
  width: 102px;
}

.nav-pills .nav-link.active {
  color: var(--color-white); /* Active tab color */
  background: linear-gradient(90.21deg, var(--color-light-blue) -5.91%, var(--color-pink) 111.58%);
  opacity: 70%;
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--color-dark-shadow);
  width: 102px;
}

@media (max-width: 991px) {
  .nav-pills {
    margin: 0 100px; 
  }
}

@media (max-width: 767px) {
  
  .nav-pills {
    flex-direction: row; 
    align-items: center; 
    margin: 0; 
  }
}

.play-app,
.projects a {
  color: var(--color-hover-text);
  font-weight: 700;
  font-size: 1.5em;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}
.play-app:hover {
  transform: scale(1.5);
}

#contained-modal-title-vcenter {
  color: var(--color-hover-text);
  font-style: italic;
  margin-left: 12%;
}
